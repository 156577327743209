

const Terms = () => {
    return (
       <div>
        <div className="hero-inn">
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                 
                   <h1 className="hero-heading">Terms & Conditions</h1>
                   <p className="text text-light">Welcome to Tincywincy.in By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>
                   </div>
                </div>
            </section>
            </div>
  
           <section className="bg-light">
            <div className="my-container" style={{padding: '60px 0'}}>
                
               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>1. Acceptance of Terms</h2>
               <p className="text text-light">Welcome to Tincywincy.in By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>2. Changes to Terms</h2>
               <p className="text text-light">We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on this page. Your continued use of the site after changes indicates your acceptance of the new terms.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>3. Use of the Site</h2>
               <p className="text text-light">You must be at least 4 years old to use this site.
               You agree to use Tincywincy.in only for lawful purposes and in a manner that does not infringe on the rights of others.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>4. Account Registration</h2>
               <p className="text text-light">You may be required to create an account to access certain features.
You agree to provide accurate, current, and complete information during registration.
You are responsible for maintaining the confidentiality of your account and password.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>5. Intellectual Property</h2>
               <p className="text text-light">All content on Tincywincy.in, including text, graphics, logos, and images, is the property of Tincywincy.in or its licensors and is protected by copyright, trademark, and other intellectual property laws.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>6. User Content</h2>
               <p className="text text-light">By submitting any content to our site, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and publish that content.
               You are responsible for any content you post, and it must not violate any laws or third-party rights.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>7. Limitation of Liability</h2>
               <p className="text text-light">Tincywincy.in will not be liable for any indirect, incidental, or consequential damages arising from your use of the site or services. Our total liability shall not exceed the amount paid by you, if any, for accessing the site.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>8. Indemnification</h2>
               <p className="text text-light">You agree to indemnify and hold harmless Tincywincy.in, its affiliates, and their respective officers, employees, and agents from any claims, losses, or damages arising from your use of the site or violation of these terms.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>9. Governing Law</h2>
               <p className="text text-light">These terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts of Nellore.
               </p>

               <h2 className="sub-heading color-primary" style={{marginTop: '30px', marginBottom: '10px'}}>10. Contact Information</h2>
               <p className="text text-light">If you have any questions about these Terms and Conditions, please contact us at tincywincyworld@gmail.com.
               </p>
            </div>
           </section>

       </div>
    )
}


export default Terms;